<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <ylomi-logo />
        </b-link>

        <b-card-title class="mb-1"> Mot de passe oublié? 🔒 </b-card-title>
        <b-card-text class="mb-2">
          Entrer le numéro de téléphone associé à votre compte et nous vous
          enverrons un code pour réinitialiser votre mot de passe
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            autocomplete="off"
            class="auth-forgot-password-form mt-2"
            @submit.prevent="applyPasswordForgotAction"
          >
            <!-- email -->
            <b-form-group
              label="Numéro de téléphone"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="téléphone"
                rules="required|min:8|regex:^(229)"
              >
                <b-form-input
                  id="forgot-password-email"
                  type="number"
                  v-model="payload.phone_number"
                  :state="errors.length > 0 ? false : null"
                  name="forgot-password-email"
                  placeholder="229 xx xx xx xx"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              :disabled="isLoading"
              variant="primary"
              block
              type="submit"
            >
              <div v-if="isLoading">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else>Envoyer</span>
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{ name: 'login' }">
            <feather-icon icon="ChevronLeftIcon" /> Retour
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import YlomiLogo from "@core/layouts/components/Logo.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BCard,
  BLink,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { mapActions } from "vuex";

export default {
  components: {
    YlomiLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      payload: {
        phone_number: "",
      },
      isLoading: false,
      required,
      email,
    };
  },
  methods: {
    ...mapActions("auth", ["passwordForgotAction"]),
    applyPasswordForgotAction() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          this.passwordForgotAction(this.payload)
            .then((response) => {
              this.isLoading = false;
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Succès",
                    icon: "CheckIcon",
                    text: response.message,
                    variant: "success",
                  },
                },
                {
                  position: "top-center",
                }
              );

              this.$router.push({ name: "reset-password" });
            })
            .catch((error) => {
              this.isLoading = false;
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Error", 
                    icon: "AlertTriangleIcon",
                    text: error.response.data.message,
                    variant: "danger",
                  },
                },
                {
                  position: "top-center",
                }
              );
            
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
